export const helpText = {
  base: {
    locale: {
      title: '言語',
      text: [
        '店舗情報を設定する言語を以下の4つから選択できます。',
        '・日本語',
        '・英語',
        '・中国語（簡体字）',
        '・中国語（繁体字）',
        '',
        '店舗マスタの注意点',
        '・店舗マスタとして保持できる情報は言語によらず1つのみですのでご注意ください。',
        '例：日本語で店舗マスタを設定した後に、英語に切り替えて店舗マスタを保存した場合、日本語の店舗マスタの情報は保持されません。',
        '',
        'Yahoo!プレイスの注意点',
        '・Yahoo!プレイスの仕様上、「日本語」を選択した時のみ店舗マスタの情報が連携されます。',
      ],
    },
    name: {
      title: '店舗名',
      text: [
        '店舗名は必須入力項目です。',
        '設定した内容は以下の媒体に自動で連携されます。',
        '・Google ビジネスプロフィール',
        '・Apple Business Connect',
        '・Yahoo!プレイス',
        '・Facebook',
        '',
        '店舗マスタの注意点',
        '・店舗名が同じ異なる店舗を登録したい場合は、異なる郵便番号を設定してください。',
        '',
        'Google ビジネスプロフィール連携の注意点',
        '・店舗名にプロモーション等を含めるとGBPのアカウントが停止されます。',
        '',
        'Apple Business Connect連携の注意点',
        '・店舗マスタの店舗名を変更した場合、Apple Business Connectで設定済みの「その他の店舗名」が上書きされます。',
      ],
    },
    brand: {
      title: 'ブランド',
      text: [
        'ブランド名は必須選択項目です。',
        '設定した内容は以下の媒体に自動で連携されます。',
        '・Apple Business Connect',
        '',
        'Apple Business Connect連携の注意点',
        '・「優先する店舗名」にはブランド名が連携されます。',
        '',
        'その他注意点',
        '・新規ブランドは管理者権限のアカウントでのみ登録できます。',
      ],
    },
    group: {
      title: 'グループ',
      text: [
        'この店舗を管理するためのグループを設定することができます。',
        'ひとつの店舗に複数のグループを設定できます。',
      ],
    },
    address: {
      title: '住所',
      text: [
        '郵便番号を含む住所を設定することができます。',
        '設定した内容は以下の媒体に自動で連携されます。',
        '・Google ビジネスプロフィール',
        '・Apple Business Connect',
        '・Yahoo!プレイス',
        '',
        '国または地域',
        '必須入力項目です。',
        '適切な値を選択してください。',
        '',
        '郵便番号',
        '郵便番号を入力してください。',
        '・日本の店舗：000-0000のように-込みで入力してください。',
        '・海外の店舗：0000000のように数字のみ入力してください。',
        '',
        '都道府県または州',
        '必須入力項目です。',
        '適切な値を選択してください。',
        '',
        '市区町村',
        '必須入力項目です。',
        '適切な値を入力してください。',
        '',
        '町名または地名',
        '必須入力項目です。',
        '適切な値を入力してください。',
        '',
        '番地',
        '必須入力項目です。',
        '適切な値を入力してください。',
        '',
        '建物名など',
        '建物名を登録できます。',
      ],
    },
    tel: {
      title: '電話番号',
      text: [
        '店舗の電話番号を設定することができます。',
        '設定した内容は以下の媒体に自動で連携されます。',
        '・Google ビジネスプロフィール',
        '・Apple Business Connect',
        '・Yahoo!プレイス',
        '・Facebook',
        '',
        'Facebook連携の注意点',
        '・店舗マスタの国名が「日本」の場合のみ連携されます。',
      ],
    },
    businessHours: {
      title: '営業時間',
      text: [
        'それぞれの曜日ごと、祝日・祝前日の営業時間を設定することができます。',
        '設定した内容は以下の媒体に自動で連携されます。',
        '・Google ビジネスプロフィール',
        '・Apple Business Connect',
        '・Yahoo!プレイス',
        '・Facebook',
        '',
        '店舗マスタの入力時の注意点',
        '・それぞれの曜日ごとに、3つの営業時間まで登録できます。',
        '・日を跨いで営業時間を登録することができます。',
        '・1分単位で営業時間を登録できます。',
        '・祝日・祝前日の営業時間はYahoo!プレイスのみに連携されます。',
        '',
        'Yahoo!プレイス連携の注意点',
        '・店舗マスタで登録した営業時間が、30分刻みとなっていない場合、Yahoo!プレイスには連携できません。',
        '',
        'Facebook連携の注意点',
        '・店舗マスタからFacebookに連携するには、Facebookのアカウントに住所が設定されている必要があります。',
        'Facebookの仕様上、カンリーからFacebookの住所は更新できないため、お手数ですが、Facebookのアカウント上で住所を更新してください。',
        '・Facebook APIの仕様上、1日の営業時間は最大2つまで連携されます。',
      ],
    },
    irregular: {
      title: '特別営業時間',
      text: [
        '特別営業時間を設定することができます。',
        '',
        'Yahoo!プレイス連携の注意点',
        '・特別営業時間を営業日として設定した場合、直近の10件が「特定営業日」として連携されます。',
        '・特別営業時間を休業日として設定した場合、直近の10件が「特定休業日」として連携されます。',
        '・店舗マスタで登録した営業時間が、30分刻みとなっていない場合、Yahoo!プレイスには連携できません。',
      ],
    },
    establish: {
      title: '開業日',
      text: [
        '店舗の開業日を設定することができます。',
        '設定した内容は以下の媒体に自動で連携されます。',
        '・Google ビジネスプロフィール',
        '・Yahoo!プレイス',
      ],
    },
    status: {
      title: '営業状況',
      text: [
        '店舗の開業、休業、閉業のステータスを設定することができます。',
        '設定した内容は以下の媒体に自動で連携されます。',
        '・Google ビジネスプロフィール',
        '・Apple Business Connect',
        '・Yahoo!プレイス',
        '',
        'Apple Business Connect連携の注意点',
        '・休業を選択すると、休業期間を設定できます。',
        '・閉業を選択すると、閉業日を設定できます。',
      ],
    },
    instagram: {
      title: 'SNSやWEBサイトのリンク（Instagram）',
      text: [
        'Instagramのリンクを任意で設定することができます。',
        '設定したSNSリンクは以下の媒体に自動で連携されます。',
        '・Google ビジネスプロフィール',
        '・Apple Business Connect',
        '・Yahoo!プレイス',
      ],
    },
    facebook: {
      title: 'SNSやWEBサイトのリンク（Facebook）',
      text: [
        'Facebookのリンクを任意で設定することができます。',
        '設定したSNSリンクは以下の媒体に自動で連携されます。',
        '・Google ビジネスプロフィール',
        '・Apple Business Connect',
        '・Yahoo!プレイス',
      ],
    },
    twitter: {
      title: 'SNSやWEBサイトのリンク（X）',
      text: [
        'X（旧Twitter）のリンクを任意で設定することができます。',
        '設定したSNSリンクは以下の媒体に自動で連携されます。',
        '・Google ビジネスプロフィール',
        '・Apple Business Connect',
        '・Yahoo!プレイス',
      ],
    },
    youtube: {
      title: 'SNSやWEBサイトのリンク（Youtube）',
      text: [
        'Youtubeのリンクを任意で設定することができます。',
        '設定したSNSリンクは以下の媒体に自動で連携されます。',
        '・Google ビジネスプロフィール',
        '・Yahoo!プレイス',
      ],
    },
    yelp: {
      title: 'SNSやWEBサイトのリンク（Yelp）',
      text: [
        'Yelpのリンクを任意で設定することができます。',
        '設定したSNSリンクは以下の媒体に自動で連携されます。',
        '・Apple Business Connect',
      ],
    },
    line: {
      title: 'SNSやWEBサイトのリンク（Line公式アカウント）',
      text: [
        'Line公式アカウントのリンクを任意で設定することができます。',
        '設定したSNSリンクは以下の媒体に自動で連携されます。',
        '・Yahoo!プレイス',
      ],
    },
    officialSite: {
      title: 'SNSやWEBサイトのリンク（公式サイト）',
      text: [
        '公式サイトのリンクを任意で設定することができます。',
        '設定したSNSリンクは以下の媒体に自動で連携されます。',
        '・Google ビジネスプロフィール：ウェブサイトURL',
        '・Apple Business Connect：ホームページURL',
        '・Yahoo!プレイス：公式サイトURL',
      ],
    },
    bookingSite: {
      title: 'SNSやWEBサイトのリンク（予約サイト）',
      text: [
        '予約サイトのリンクを任意で設定することができます。',
        '設定したSNSリンクは以下の媒体に自動で連携されます。',
        '・Google ビジネスプロフィール',
        '・Yahoo!プレイス',
      ],
    },
    shoppingSite: {
      title: 'SNSやWEBサイトのリンク（ECサイト）',
      text: [
        'ECサイトのリンクを任意で設定することができます。',
        '設定したSNSリンクは以下の媒体に自動で連携されます。',
        '・Google ビジネスプロフィール：予約サイト',
        '・Yahoo!プレイス：通販サイト',
      ],
    },
    submitLink: {
      title: 'SNSやWEBサイトのリンク（投稿用リンク）',
      text: [
        '複数の店舗へ一括投稿する際に、ボタンの遷移先として設定可能なリンクを任意で設定することができます。',
        '店舗毎に異なる予約サイトのリンク等を設定して、投稿機能を便利に活用しましょう。',
      ],
    },
    code: {
      title: '店番',
      text: [
        '店舗を識別するためのユニークな番号を設定できます。',
        '設定した内容は以下の媒体に自動で連携されます。',
        '・Google ビジネスプロフィール',
      ],
    },
  },
  google: {
    name: {
      title: 'ビジネス名',
      text: [
        'この店舗の正式名称を設定してください',
        '例）居酒屋カンリー 目黒店',
        '※キャッチコピーやタグは入力しないでください',
        '',
        '（店舗マスタの「店舗名」が反映されています）',
      ],
    },
    mainCategory: {
      title: 'メインカテゴリ',
      text: [
        'この店舗に該当するカテゴリ（業態）を1つ選択してください。',
        '',
        '例）居酒屋、カフェ、ヘアサロン',
        '',
        'なお、一部のカテゴリではGoogleの仕様により、カンリーから「サービス」の登録ができません。',
        '登録の際にエラーが発生した場合は、GBPの管理画面から登録をお願いいたします。',
        '',
        '「サービス」をカンリーから登録できないカテゴリの例（一部抜粋）：',
        '倉庫、セルフストレージ施設、建材店、金物店、作業服店、工具レンタル業、雑貨店、ギフト雑貨ショップ、化粧品、文房具、調剤薬局、スーパーマーケット',
      ],
    },
    additionalCategory: {
      title: '追加カテゴリ',
      text: ['（上記「メインカテゴリ」と同じ）'],
    },
    address: {
      title: '住所',
      text: [
        'この店舗の住所を設定してください',
        '（店舗マスタの「住所」が反映されています）',
      ],
    },
    area: {
      title: 'サービス提供地域',
      text: [
        'この店舗で商品配達や出張型サービスを提供している場合のみ、その対象地域を選択してください',
        '例）東京都、東京都目黒区、埼玉県さいたま市',
      ],
    },
    businessHours: {
      title: '営業時間',
      text: [
        'この店舗の営業時間を設定してください',
        '（店舗マスタの「営業時間」が反映されています）',
      ],
    },
    hours: {
      title: '営業時間の詳細',
      text: [
        '宅配・テイクアウト・ドライブスルー・店舗受け取りなど、この店舗で提供している特定のサービスの営業時間を設定してください',
      ],
    },
    businessDates: {
      title: '特別営業時間',
      text: [
        'この店舗で、営業時間が通常とは異なる日がある場合は設定してください',
        '例）祝日、貸切がある日、定休日ではないが営業しない日',
      ],
    },
    tel: {
      title: '電話番号',
      text: [
        'この店舗の電話番号を設定してください',
        '（店舗マスタの「電話番号」が反映されています）',
      ],
    },
    website: {
      title: 'ウェブサイト',
      text: [
        'この店舗のウェブサイトURLを設定してください',
        '例）https://jp.can-ly.com/',
        '※誘導したいサイトのURLでも設定可能です',
      ],
    },
    snsLinks: {
      title: 'SNSリンク',
      text: [
        'この店舗のソーシャルメディアURLを設定してください',
        '（Instagram・Facebook・X(旧Twitter)のリンクは、店舗マスタの「ソーシャルメディアURL」が反映されています）',
      ],
    },
    placeActionLinks: {
      APPOINTMENT: {
        title: '予約',
        text: [
          'この店舗の予約ページURLを設定してください',
          '',
          '例）自社HP・お店独自で制作したホームページの予約リンク',
        ],
      },
      ONLINE_APPOINTMENT: {
        title: 'オンライン診察',
        text: [
          'この店舗のオンライン診察ページURLを設定してください',
          '',
          '例）自社ホームページ・お店独自で制作したホームページの予約リンク',
        ],
      },
      DINING_RESERVATION: {
        title: 'レストラン予約',
        text: [
          'この店舗の予約ページURLを設定してください',
          '',
          '例）自社ホームページ・お店独自で制作したホームページの予約リンク',
        ],
      },
      FOOD_ORDERING: {
        title: 'デリバリー・テイクアウト',
        text: [
          'この店舗のデリバリー・テイクアウトページURLを設定してください',
          '',
          '例）自社ホームページ・お店独自で制作したホームページのリンク',
        ],
      },
      FOOD_DELIVERY: {
        title: 'デリバリー',
        text: [
          'この店舗のデリバリーページURLを設定してください',
          '',
          '例）自社ホームページ・お店独自で制作したホームページのリンク',
        ],
      },
      FOOD_TAKEOUT: {
        title: 'テイクアウト',
        text: [
          'この店舗のテイクアウトページURLを設定してください',
          '',
          '例）自社ホームページ・お店独自で制作したホームページのリンク',
        ],
      },
      SHOP_ONLINE: {
        title: 'オンラインショップ',
        text: [
          'この店舗のオンラインショップページURLを設定してください',
          '',
          '例）自社ホームページ・お店独自で制作したホームページのリンク',
        ],
      },
    },
    attributes: {
      title: '属性',
      text: [
        'この店舗の特長を設定してください',
        '例）テラス席やWi-Fi の有無、決済方法など',
      ],
    },
    businessInfo: {
      title: 'ビジネス情報',
      text: [
        'この店舗の特長や、この店舗で提供しているサービス・商品に関する情報を設定してください',
        '例）京王井の頭線渋谷駅から徒歩1分、山手線渋谷駅から徒歩3分にある駅近のイタリアンレストランです。こじんまりとした落ち着いた空間で、大切なパートナーやご友人との時間をゆったりと過ごすことができます。',
        'イタリア産のボトルワインやビール、お酒が飲めない方には見た目もおしゃれなノンアルコールカクテルなど、豊富に取り揃えております。お酒と一緒に楽しめるお食事メニューのご用意もございます。',
        'お家に帰る前に、気の合う仲間や気さくなスタッフと素敵な時間を過ごしませんか？',
        'お気軽にご予約ください！心よりご来店お待ちしております。',
      ],
    },
    establishmentDate: {
      title: '開業日',
      text: ['この店舗の開業日を設定してください'],
    },
    code: {
      title: '店舗コード',
      text: [
        'この店舗の「店舗コード」を設定してください',
        '（店舗アカウントマスタの「店番」が反映されています）',
      ],
    },
    status: {
      title: '営業状況',
      text: [
        'この店舗の営業状況を設定してください',
        '（店舗アカウントマスタの「休業設定」が反映されています）',
      ],
    },
    assetItems: {
      ADDITIONAL: {
        title: 'オーナー提供',
        text: ['Googleビジネスプロフィールのオーナーが追加した写真です。'],
      },
      CUSTOMERS: {
        title: 'ユーザー提供',
        text: [
          '実際に店舗を訪れたお客様が、クチコミなどでアップロードした写真です。',
        ],
      },
      COVER: {
        title: 'カバー',
        text: [
          '店舗のカバー写真です。店舗をもっともよく表す写真を1枚設定してください。',
          'Googleの仕様により、設定した写真が Google 上で最初に表示されない場合があります。',
        ],
      },
      PROFILE: {
        title: 'プロフィール',
        text: ['', ''],
      },
      LOGO: {
        title: 'ロゴ',
        text: [
          '店舗のロゴ写真です。',
          'お客様が店舗を見つけやすいように、ロゴ写真を1枚設定してください。',
        ],
      },
      EXTERIOR: {
        title: '外見',
        text: [
          '店舗の外観写真です。',
          'お客様が店舗を見つけやすいように、店舗の入口や周辺環境等の外観写真を設定してください。',
        ],
      },
      INTERIOR: {
        title: '店内',
        text: [
          '店舗の店内写真です。',
          '店舗の雰囲気がお客様に伝わるように、内装などの店内写真を設定してください。',
        ],
      },
      FOOD_AND_DRINK: {
        title: '食品と飲料',
        text: [
          '店舗の食べ物や飲み物の写真です。',
          'お客様が食事のプランを考えやすいように、食べ物や飲み物の写真を設定してください。',
        ],
      },
      PRODUCT: {
        title: '商品',
        text: [
          '店舗の商品写真です。',
          '扱っている商品がお客様に伝わるように、代表的な商品や人気の高い商品の写真を設定してください。',
        ],
      },
      MENU: {
        title: 'メニュー',
        text: [
          '店舗のメニュー写真です。',
          '提供しているメニューがお客様に伝わるように、代表的なメニューや人気の高いメニューの写真を設定してください。',
          'メニュー情報と一緒にアップロードした写真もこの項目に表示されます。',
        ],
      },
      TEAMS: {
        title: 'チーム',
        text: [
          '店舗スタッフの写真です。',
          'お客様にスタッフの人となりを感じてもらえるように、店舗スタッフの写真を設定してください。',
        ],
      },
      AT_WORK: {
        title: '職場',
        text: [
          '職場環境の写真です。',
          'お客様に職場の雰囲気が伝わるように、職場やオフィスの写真を設定してください。',
        ],
      },
      COMMON_AREA: {
        title: '共用エリア',
        text: [
          '共有エリアの写真です。',
          '出張やレジャーの計画を立てているお客様に雰囲気が伝わるように、温泉や朝食会場などの共有エリアの写真を設定してください。',
        ],
      },
      ROOMS: {
        title: '部屋',
        text: [
          '客室の写真です。',
          '出張やレジャーの計画を立てているお客様に雰囲気が伝わるように、さまざまなタイプの客室の写真を設定してください。',
        ],
      },
      CATEGORY_UNSPECIFIED: {
        title: 'オーナー提供',
        text: ['Googleビジネスプロフィールのオーナーが追加した写真です。'],
      },
    },
    menus: {
      title: 'メニュー',
      text: [
        '飲食ビジネスの店舗の場合、Googleビジネスプロフィールにメニューの一覧を表示できます。',
        '来店を検討しているお客様に向けて、店舗で提供している商品や料理などのメニュー情報を設定してください。',
      ],
    },
    services: {
      title: 'サービス',
      text: [
        '飲食ビジネス以外の店舗の場合、Googleビジネスプロフィールにサービスの一覧を表示できます。',
        '来店を検討しているお客様に向けて、店舗で提供しているサービス情報を設定してください。',
      ],
    },
  },
  facebook: {
    pageName: {
      title: 'ページ名',
      text: [
        'Facebookページの名前です。',
        'Facebook APIの仕様上、カンリーでは編集できません。',
      ],
    },
    userName: {
      title: 'ユーザーネーム',
      text: [
        'FacebookページのURL末尾として使用される文字列です。',
        'Facebook APIの仕様上、カンリーでは編集できません。',
      ],
    },
    about: {
      title: 'ページ情報',
      text: [
        'この店舗の説明を記載してください。',
        '例）東京都内に○店舗を展開しているカンリー目黒店の公式Facebookページです。',
      ],
    },
    impressum: {
      title: '所属者情報',
      text: [
        'この店舗を運営している会社名を記載してください。',
        '例）株式会社カンリー',
      ],
    },
    category: {
      title: 'カテゴリ',
      text: ['この店舗のカテゴリ（業態）を選択してください。'],
    },
    tel: {
      title: '電話番号',
      text: [
        'この店舗の電話番号です。',
        '店舗マスタの「電話番号」の情報が反映されています。',
        'なお、日本以外の電話番号は更新できません。',
      ],
    },
    website: {
      title: 'ウェブサイト',
      text: [
        'この店舗の公式ウェブサイトURLを記載してください。',
        '例）https://jp.can-ly.com/',
      ],
    },
    emails: {
      title: 'メールアドレス',
      text: ['この店舗で使用しているメールアドレスを記載してください。'],
    },
    priceRange: {
      title: '価格帯',
      text: ['この店舗における価格帯を選択してください。'],
    },
    businessHours: {
      title: '営業時間',
      text: [
        'この店舗の営業時間です。',
        '店舗マスタの「営業時間」が反映されています。',
      ],
    },
    description: {
      title: 'その他の情報',
      text: ['この店舗に関するその他の情報を記載してください。'],
    },
  },
  instagram: {
    userName: {
      title: 'ユーザーネーム',
      text: [
        '「@」以降の英数字で設定する固有IDです。',
        'Instagram APIの仕様上、カンリーでは編集できません。',
      ],
    },
  },
  apple: {},
  yahoo: {
    placeId: {
      title: 'プレイスID',
      text: [
        'Yahoo!プレイスに反映済みの情報を表示しています。',
        '',
        '施設のYahoo!プレイスURL末尾を確認できます。（編集できません）',
      ],
    },
    name: {
      title: '店舗名',
      text: [
        'Yahoo!プレイスに反映済みの情報を表示しています。',
        '',
        '更新が必要な場合、店舗マスタの店舗名を修正してください。',
      ],
    },
    ruby: {
      title: '店舗名フリガナ',
      text: [
        'Yahoo!プレイスに反映済みの情報を表示しています。',
        '',
        '店舗名のフリガナを確認できます。',
      ],
    },
    category: {
      title: '業種カテゴリ',
      text: [
        'Yahoo!プレイスに反映済みの情報を表示しています。',
        '',
        '店舗のカテゴリ（業種）を確認できます。',
        'メインカテゴリとサブカテゴリ合わせて、最大4つまで設定できます。',
      ],
    },
    tel: {
      title: '電話番号',
      text: [
        'Yahoo!プレイスに反映済みの情報を表示しています。',
        '',
        '更新が必要な場合、店舗マスタの電話番号を修正してください。',
      ],
    },
    latlng: {
      title: '緯度経度',
      text: [
        'Yahoo!プレイスに反映済みの情報を表示しています。',
        '',
        '店舗の緯度経度を確認できます。',
      ],
    },
    address: {
      title: '住所',
      text: [
        'Yahoo!プレイスに反映済みの情報を表示しています。',
        '',
        '更新が必要な場合、店舗マスタの住所を修正してください。',
      ],
    },
    businessHours: {
      title: '営業時間',
      text: [
        'Yahoo!プレイスに反映済みの情報を表示しています。',
        '',
        '更新が必要な場合、店舗マスタの営業時間を修正してください。',
      ],
    },
    irregular: {
      title: '特別営業時間',
      text: [
        'Yahoo!プレイスに反映済みの情報を表示しています。',
        '',
        '更新が必要な場合、店舗マスタの特別営業時間を修正してください。',
        '・店舗マスタの特別営業時間を営業日として設定した場合、直近の10件が「特定営業日」として連携されます。',
        '・店舗マスタの特別営業時間を休業日として設定した場合、直近の10件が「特定休業日」として連携されます。',
      ],
    },
    businessHoursDescription: {
      title: '営業時間の説明',
      text: [
        'Yahoo!プレイスに反映済みの情報を表示しています。',
        '',
        '営業時間に関する特記事項等を入力できます。',
      ],
    },
    officialSite: {
      title: '公式サイトURL',
      text: [
        'Yahoo!プレイスに反映済みの情報を表示しています。',
        '',
        '更新が必要な場合、店舗マスタの公式サイトURLを修正してください。',
      ],
    },
    shoppingSite: {
      title: 'ECサイトURL',
      text: [
        'Yahoo!プレイスに反映済みの情報を表示しています。',
        '',
        '更新が必要な場合、店舗マスタのECサイトリンクを修正してください。',
      ],
    },
    bookingSite: {
      title: '予約サイトURL',
      text: [
        'Yahoo!プレイスに反映済みの情報を表示しています。',
        '',
        '更新が必要な場合、店舗マスタの予約サイトリンクを修正してください。',
      ],
    },
    snsLinks: {
      tiktok: {
        title: 'SNSアカウントURL（Tiktok）',
        text: [
          'Yahoo!プレイスに反映済みの情報を表示しています。',
          '',
          '店舗のTiktokアカウントURLを確認できます。',
        ],
      },
      other: {
        title: 'SNSアカウントURL（Tiktok以外）',
        text: [
          'Yahoo!プレイスに反映済みの情報を表示しています。',
          '',
          '更新が必要な場合、店舗マスタのSNSまたはウェブサイトのリンクを修正してください。',
        ],
      },
    },
    payment: {
      title: '提供サービス（決済情報）',
      text: [
        'Yahoo!プレイスに反映済みの情報を表示しています。',
        '',
        '店舗の決済方法を設定できます。',
      ],
    },
    attributes: {
      title: '提供サービス（その他）',
      text: [
        'Yahoo!プレイスに反映済みの情報を表示しています。',
        '',
        '店舗の設備やサービスを設定できます。',
      ],
    },
    description: {
      title: '施設に関する説明文',
      text: [
        'Yahoo!プレイスに反映済みの情報を表示しています。',
        '',
        '店舗（施設）に関する説明文を設定できます。',
      ],
    },
    establish: {
      title: '開業日',
      text: [
        'Yahoo!プレイスに反映済みの情報を表示しています。',
        '',
        '更新が必要な場合、店舗マスタの開業日を修正してください。',
      ],
    },
    budget: {
      title: '平均予算（ランチ・ディナー）',
      text: [
        'Yahoo!プレイスに反映済みの情報を表示しています。',
        '',
        '飲食業の場合のみ利用いただけます。',
      ],
    },
    assetItems: {
      COVER: {
        title: '店舗の写真（カバー）',
        text: [
          'Yahoo!プレイスに反映済みの情報を表示しています。',
          '',
          '注意点',
          '・1枚のみ設定可能です。',
          '・登録可能なファイルタイプ：jpg、jpeg、gif、png',
          '・個別ファイルサイズ（容量）：10MB以下',
          '・個別ファイルサイズ；横720px以上、縦720px以上',
        ],
      },
      SECONDARY: {
        title: '店舗の写真（製品・サービス）',
        text: [
          'Yahoo!プレイスに反映済みの情報を表示しています。',
          '',
          '注意点',
          '・全カテゴリの写真含め最大200枚まで設定可能です。',
          '・登録可能なファイルタイプ：jpg、jpeg、gif、png',
          '・個別ファイルサイズ（容量）：10MB以下',
          '・個別ファイルサイズ；横720px以上、縦720px以上',
        ],
      },
      INSIDE: {
        title: '店舗の写真（内観）',
        text: [
          'Yahoo!プレイスアカウントに反映済みの情報を表示しています。',
          '',
          '注意点',
          '・全カテゴリの写真含め最大200枚まで設定可能です。',
          '・登録可能なファイルタイプ：jpg、jpeg、gif、png',
          '・個別ファイルサイズ（容量）：10MB以下',
          '・個別ファイルサイズ；横720px以上、縦720px以上',
        ],
      },
      OUTSIDE: {
        title: '店舗の写真（外観）',
        text: [
          'Yahoo!プレイスアカウントに反映済みの情報を表示しています。',
          '',
          '注意点',
          '・全カテゴリの写真含め最大200枚まで設定可能です。',
          '・登録可能なファイルタイプ：jpg、jpeg、gif、png',
          '・個別ファイルサイズ（容量）：10MB以下',
          '・個別ファイルサイズ；横720px以上、縦720px以上',
        ],
      },
      ETC: {
        title: '店舗の写真（その他）',
        text: [
          'Yahoo!プレイスアカウントに反映済みの情報を表示しています。',
          '',
          '注意点',
          '・全カテゴリの写真含め最大200枚まで設定可能です。',
          '・登録可能なファイルタイプ：jpg、jpeg、gif、png',
          '・個別ファイルサイズ（容量）：10MB以下',
          '・個別ファイルサイズ；横720px以上、縦720px以上',
        ],
      },
    },
  },
};
